<template>
	<ion-content>
		<div class="title-container">
			<ion-row class="title-content">
				<ion-col class="title-text-only">
					<div class="title-content-avatar">
						<ion-avatar class="title-content-avatar-image">
							<img src="@/assets/misc/placeholder.png" v-if="isPageLoading === true">
							<img :src="picture" v-if="isPageLoading === false">
						</ion-avatar>
					</div>
					<div class="title-content-text" v-if="isPageLoading === false">
						<h1 class="title-content-name">Welcome, {{name}}!</h1>
						<p class="title-content-subheader">Today is <span class="title-content-notice">{{currentDate}}</span></p>
					</div>
					<div class="title-content-text" v-if="isPageLoading === true">
						<h1 class="title-content-name">Loading user profile....</h1>
					</div>
				</ion-col>
			</ion-row>
		</div>
		<div class="content-container">
			<div class="content-status-container" v-if="isPageLoading === false">
				<ion-card class="content-container-card" color="primary">
					<ion-item class="content-subheader" color="primary">
						<ion-icon class="message-icon" name="information-circle-outline" color="light" start="start"></ion-icon>
						<ion-label>COVID-19 Guidelines</ion-label>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col class="message-container" size="9">
								<div start="start">
									<div class="message-title-container">
										<h1 class="message-text">What to do when an employee experiences COVID-related Symptoms?</h1>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<div end="end">
									<ion-button class="card-action-button-change" color="light" target="_blank" href="https://drive.google.com/file/d/1VcFMCq-BEBiSWuwZuLiZbH_EggfUtsKs/view">
										<ion-icon slot="start"  name="book-outline"></ion-icon> Read Here
									</ion-button>
								</div>
							</ion-col>
						</ion-row>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card" v-if="isFilledToday === true" :color="getTodayAnswered.has_symptoms === false ? 'success' : 'danger'">
					<ion-item class="content-subheader" :color="getTodayAnswered.has_symptoms === false ? 'success' : 'danger'">
						<ion-icon class="message-icon" color="light" name="fitness" start="start"></ion-icon>
						<ion-label>Health Check</ion-label>
						<ion-button class="message-update-button" fill="outline" end="end" color="light" @click="updateForm(getTodayAnswered.id)">View / Update Form</ion-button>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col class="message-container" size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-icon class="message-icon" :name="getTodayAnswered.has_symptoms === false ? 'happy-sharp' : 'sad-sharp'" size="large"></ion-icon>
										<h1 class="message-text">{{getTodayAnswered.has_symptoms === false ? 'You are feeling well today' : 'You are not feeling well today'}}</h1>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3" v-if="getTodayAnswered.has_symptoms === true">
								<div end="end">
									<ion-button v-if="getTodayAnswered.has_symptoms === true" class="card-action-button-change" color="light" @click="goToEmergencyContacts">
										<ion-icon slot="start"  name="call"></ion-icon> SOS
									</ion-button>
								</div>
							</ion-col>
						</ion-row>
						<ion-row class="message-content-container">
							<p>{{getTodayAnswered.has_symptoms === false ? 'Stay safe and follow health protocols.' : 'If symptom worsens, please seek immediate medical attention and inform HR.'}}</p>
						</ion-row>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card" v-if="isFilledYesterday === false">
					<ion-item class="content-subheader">
						<ion-icon class="message-icon" color="danger" :icon="fitness" start="start"></ion-icon>
						<ion-label>Health Check</ion-label>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-icon class="message-icon" :icon="heartDislikeOutline" size="large" color="danger"></ion-icon>
										<h1 class="message-text">Not Filled out</h1>
									</div>
									<div class="message-content-container">
										<p>You haven't filled out the monitoring checklist for {{previousWorkDate}}. Please fill-out the form immediately.</p>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<div end="end">                  
									<ion-button fill="solid" @click="fillOutButton('previousDay')">Complete Form</ion-button>
								</div>
							</ion-col>
						</ion-row>
					</ion-card-content>
				</ion-card>

				<ion-card class="content-container-card" v-if="isFilledToday === false">
					<ion-item class="content-subheader">
						<ion-icon class="message-icon" color="danger" :icon="fitness" start="start"></ion-icon>
						<ion-label>Health Check</ion-label>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col size="6">
								<div start="start">
									<div class="message-title-container">
										<ion-icon class="message-icon" :icon="warning" size="large" color="warning"></ion-icon>
										<h1 class="message-text">Not yet filled</h1>
									</div>
									<div class="message-content-container">
										<p>You haven't filled out the monitoring checklist for today. Please fill-out the form within today.</p>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="6">
								<div end="end">
									<ion-button v-if="isFilledYesterday === true" fill="solid" @click="resubmitButton">Submit Using Yesterday's Form</ion-button>
									<ion-button fill="solid" @click="fillOutButton('today')">Manual Fill-out</ion-button>
								</div>
							</ion-col>
						</ion-row>
					</ion-card-content>
				</ion-card>
			</div>
			<div class="content-status-container" v-if="isPageLoading === true">
				<ion-card class="content-container-card">
					<ion-item class="content-subheader">
						<ion-skeleton-text start="start" class="message-icon" animated style="width: 20px; height: 20px; border-radius: 50%; margin-left: 15px"></ion-skeleton-text>
						<ion-label><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></ion-label>
						<ion-skeleton-text class="message-update-button" end="end" animated style="width: 20%; height: 50%;"></ion-skeleton-text>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col class="message-container" size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-skeleton-text class="message-icon" animated style="width: 30px; height: 30px; border-radius: 50%;"></ion-skeleton-text>
										<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<ion-skeleton-text end="end" animated style="width: 30%; height: 100%; float: right;"></ion-skeleton-text>
							</ion-col>
						</ion-row>
						<ion-row class="message-content-container">
							<ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
							<ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
						</ion-row>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card">
					<ion-item class="content-subheader">
						<ion-skeleton-text start="start" class="message-icon" animated style="width: 20px; height: 20px; border-radius: 50%; margin-left: 15px"></ion-skeleton-text>
						<ion-label><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></ion-label>
						<ion-skeleton-text class="message-update-button" end="end" animated style="width: 20%; height: 50%;"></ion-skeleton-text>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col class="message-container" size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-skeleton-text class="message-icon" animated style="width: 30px; height: 30px; border-radius: 50%;"></ion-skeleton-text>
										<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<ion-skeleton-text end="end" animated style="width: 30%; height: 100%; float: right;"></ion-skeleton-text>
							</ion-col>
						</ion-row>
						<ion-row class="message-content-container">
							<ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
							<ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
						</ion-row>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card">
					<ion-item class="content-subheader">
						<ion-skeleton-text start="start" class="message-icon" animated style="width: 20px; height: 20px; border-radius: 50%; margin-left: 15px"></ion-skeleton-text>
						<ion-label><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></ion-label>
						<ion-skeleton-text class="message-update-button" end="end" animated style="width: 20%; height: 50%;"></ion-skeleton-text>
					</ion-item>
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col class="message-container" size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-skeleton-text class="message-icon" animated style="width: 30px; height: 30px; border-radius: 50%;"></ion-skeleton-text>
										<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<ion-skeleton-text end="end" animated style="width: 30%; height: 100%; float: right;"></ion-skeleton-text>
							</ion-col>
						</ion-row>
						<ion-row class="message-content-container">
							<ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
							<ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
						</ion-row>
					</ion-card-content>
				</ion-card>
			</div>
		</div>
	</ion-content>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, sub } from 'date-fns'
import { fitness, warning, checkmarkCircle, heartDislikeOutline } from 'ionicons/icons';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';
import store from '../store/index';

import { 
	IonIcon, 
	IonButton,
	IonRow,
	IonCardContent,
	IonCard,
	IonLabel,
	IonItem,
	IonCol,
	IonContent,
	IonAvatar,
	alertController,
	toastController,
	loadingController,
	IonSkeletonText
} from '@ionic/vue';


export default {
	name: 'DashboardContainer',
	props: {
    answeredForms: {
			type: Array
		},
		userData: {
			type: Object
		},
		isPageLoading: {
			type: Boolean
		},
		name: {
			type: String
		},
		picture: {
			type: String
		}
  },
	components: { 
		IonIcon,
		IonButton,
		IonRow,
		IonCardContent,
		IonCard,
		IonLabel,
		IonItem,
		IonCol,
		IonContent,
		IonAvatar,
		IonSkeletonText
	},
	setup() {
		return { fitness, warning, checkmarkCircle, heartDislikeOutline }
	},
	data() {
		return {
			currentDate: '',
			isFilledToday: '',
			isFilledYesterday: '',
			previousWorkDate: '',
			getAnsweredList: [],
			getTodayAnswered: {},
			getPreviousDayAnswered: {},
		};
	},
	computed: {
		...mapGetters([
			"login/currUser",
		]),
	},
	watch: {
		isPageLoading: {
			handler() {},
			deep: true
		},
		answeredForms: {
			handler(val) {
				this.getAnsweredStatus(val);
			},
			deep: true
		}
	},
	created() {
		this.currentDate = format(new Date(), 'MMMM d, yyyy');
	},
	methods: {
		async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
					cssClass: 'toast-custom',
					end: true
				})
			return toast.present();
		},
		async dismissLoading() {
			const loading = await loadingController;
			loading.dismiss()
		},
		async presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: 'loading-container',
					message: 'Please wait...',
					duration: this.timeout,
				});
			await loading.present();
		},
		async fillOutButton(type) {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Accept Terms and Conditions',
				backdropDismiss: false,
				message: 'I hereby authorize PETNET, Inc. to collect and process the data indicated herein for the purpose of implementing control of the COVID-19 infection.<br><br>I understand that my personal information is protected by RA 10173, Data Privacy Act of 2012, and that I am required by RA 11469, Bayanihan to Heal as One Act, to provide truthful information.',
				inputs: [
				{
					type: 'checkbox',
					label: 'I agree',
					value: 'isConfirmChecked',
					checked: false,
				}],
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Continue',
						handler: (data) => {
							if (data.length > 0) {
									if (data[0] === 'isConfirmChecked') {

										if (type === 'previousDay') {
											localStorage.setItem('previous_date', this.previousWorkDate);
										} else {
											localStorage.removeItem('previous_date');
										}

										this.$router.push('/health-checklist-form');
										localStorage.setItem('is_consent_agreed', true);
									} else {
										this.openToast('You must agree to the consent.', 'danger')
									}   
							} else {
								this.openToast('You must agree to the consent.', 'danger')
							}
						},
					},
				],
			});

			return alert.present();
		},
		async resubmitButton() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Resubmit Form for Today',
				backdropDismiss: false,
				message: 'Are you sure you want to re-submit the previously submitted form from yesterday?<br><br>Therefore, you hereby state that the above declaration are true and correct to the best of your knowledge. Any misrepresentation thereof, resulting to compromised health and safety of your coworkers shall be subject to disciplinary action.',
				inputs: [
				{
					type: 'checkbox',
					label: 'I agree',
					value: 'isConfirmChecked',
					checked: false,
				}],
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Resubmit Form from Yesterday',
						handler: (data) => {
							if (data.length > 0) {
									if (data[0] === 'isConfirmChecked') {
										this.resubmitPreviousForm(this.getPreviousDayAnswered);
									} else {
										this.openToast('You must agree to the consent.', 'danger')
									}   
							} else {
								this.openToast('You must agree to the consent.', 'danger')
							}
						},
					},
				],
			});

			return alert.present();
		},
		getAnsweredStatus(items) {
			let getCurrentDate = format(new Date(), 'yyyy-MM-dd');

			let checkFilledToday = items.filter(item => {
				return item.date === getCurrentDate;
			});

			this.getTodayAnswered = checkFilledToday[0];

			if (checkFilledToday.length > 0) {
				this.isFilledToday = true;
				this.getTodayAnswered = checkFilledToday[0];
			} else {
				this.isFilledToday = false;
			}

			let getPreviousDate = sub(new Date(), {days: 1});
			let formatPreviousDate = format(getPreviousDate, 'yyyy-MM-dd')
			let getLastOfficeDate = '';

			getLastOfficeDate = format(new Date(formatPreviousDate), 'yyyy-MM-dd')
			this.previousWorkDate = getLastOfficeDate;

			let checkFilledPreviousDay = items.filter(item => {
				return item.date === getLastOfficeDate;
			});

			if (checkFilledPreviousDay.length > 0) {
				this.getPreviousDayAnswered = checkFilledPreviousDay[0];
				this.isFilledYesterday = true;
			} else {
				this.isFilledYesterday = false;
			}
		},
		goToEmergencyContacts() {
			this.$router.push('/emergency');
		},
		updateForm(id) {
			this.$router.push(`/update-health-form/${id}`, {
				form_id: id
			});
		},
		async resubmitPreviousForm(item) {
			this.presentLoading();
			let current_user = this.userData;

			let bytes = "";
			let decryptedData = "";

			let cipherData = VueCookies.get('misc');

			if (this.userData) {
				current_user = this.userData;
			} else {
				bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
				decryptedData = bytes.toString(CryptoJS.enc.Utf8);

				if (decryptedData) {
					let decode = JSON.parse(decryptedData);
					let user = decode.user;
					current_user = user;
				} else {
					localStorage.setItem('is_session_expired', true);
					localStorage.removeItem("is_consent_agreed");
					VueCookies.remove("misc");
					VueCookies.remove("misc-hs");
					window.location.href = '/login';
				}
			}

			let getCurrentDate = format(new Date(), 'yyyy-MM-dd');

			let payload = {
				date: getCurrentDate,
				has_symptoms: item.has_symptoms,
				has_sore_throat: item.has_sore_throat,
				has_body_pains: item.has_body_pains,
				has_headache: item.has_headache,
				has_fever: item.has_fever,
				has_loss_taste_smell: item.has_loss_taste_smell,
				has_diarrhea: item.has_diarrhea,
				has_chest_pains: item.has_chest_pains,
				has_conjunctivitis: item.has_conjunctivitis,
				has_confirmed_case: item.has_confirmed_case,
				has_worked_with_confirmed_case: item.has_worked_with_confirmed_case,
				has_contact_with_symptoms: item.has_contact_with_symptoms,
				has_travelled_outside_ph: item.has_travelled_outside_ph,
				has_travelled_outside_home: item.has_travelled_outside_home,
				places_visited: item.places_visited,
				branch: current_user.branch,
				first_name: current_user.first_name,
				last_name: current_user.last_name,
				user_id: current_user.id,
				email: current_user.email,
				work_category: current_user.work_category,
				department: current_user.assigned_department,
				enable_teleconsultation: current_user.enable_teleconsultation,
				agreement: item.agreement,
				declaration: true
			};

			await store.dispatch('questionnaire/submitForm', payload).then(res => {
				this.dismissLoading();
				const that = this;

				setTimeout(function() {
					that.$emit('reload-content');
					that.openToast(`<b>${res.data.message}</b>`, 'success');
				}, 1000);
			}).catch((err) => {
				this.dismissLoading();

				let errorFields = '';
				let errorMessage = err.errors;

				for (let key in errorMessage) {
						errorFields += errorMessage[key][0] + '<br>';
				}

				const that = this;

				setTimeout(function() {
					that.openToast('<b>Sorry, Invalid fields:</b><br><br>' + errorFields, 'danger')
				}, 1000);
			});
		} 
	}
};
</script>
<style scoped>
	.title-container {
		padding: 25px 11px;
		
		background: rgb(2,0,36);
    background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%);
	}
	
	.title-container h1 {
		font-weight: bolder;
	}

	.title-content-avatar {
		margin: auto 15px;
		margin-left: 0;
	}

	.title-content-name {
		font-family: "Roboto Bold";
		font-size: 20px;
		margin-bottom: 0;
	}

	.title-content {
		display: flex;
		flex-direction: row;
		color: #FFF;
	}

	.title-content-notice {
		font-family: "Roboto Medium";
	}

	.title-text-only {
    display: flex;
    flex-direction: row;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

	.message-icon {
		margin-right: 5px;
	}

	.content-subheader .message-icon {
		padding-left: 15px;
	}

	.message-update-button {
		padding-right: 5px;
	}

	.message-text {
		margin: 2px;
	}

	.card-action-button { 
		text-align: left;
	}

	.card-action-button-change {
		margin: 0;
		width: 100%;
	}

	.message-container {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}

	.message-title-container {
		display: flex;
		flex-direction: row;
	}

	.message-content-container {
		display: flex;
		flex-direction: row;
		margin-top: 5px;
	}

	.content-subheader::part(native) {
		padding: 0 !important;
		margin: 0 !important;
		font-family: "Roboto Regular";
		border-bottom: 1px solid transparent;
	}

	.title-content {
    margin: 0px auto;
  }

	.title-content-subheader {
    margin-top: 5px;
  }

	.content-item-container {
		display: block
	}

	.title-content-avatar-image {
		border: 3px solid white;
		background: #FFF;
		width: 85px;
		height: 85px;
	}

	@media (min-width: 768px) {
		.content-container-card,
		.button-container {
			width: 700px;
			margin: 25px auto;
		}

		.title-content {
			width: 700px;
		}
			
		.title-content-name,
		.content-card-title h1 {
			font-size: 26px;
		}

		.content-card-data {
			font-size: 20px;
		}

		.content-item-container {
			display: flex;
		}

		.card-action-button { 
			text-align: right;
		}

		.card-action-button-change {
			width: initial;
		}
	}
</style>