<template>
  <ion-page>
    <Navbar />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <DashboardContainer @reload-content="getAnsweredQuestionnaires" v-model:userData.sync="currentUser" v-model:answeredForms.sync="answeredForms" v-model:isPageLoading.sync="isPageLoading" v-model:name.sync="name" v-model:picture.sync="picture" />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, toastController, IonRefresher, IonRefresherContent } from '@ionic/vue';
import DashboardContainer from '@/components/DashboardContainer.vue';
import Navbar from '@/components/partials/Navbar.vue';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';


export default  {
  name: 'dashboard',
  components: { DashboardContainer, Navbar, IonContent, IonPage, IonRefresher, IonRefresherContent },
  data() {
    return {
      answeredForms: [],
      currentUser: {},
      name: '',
      picture: '',
      isPageLoading: true
    };
  },
  watch: {
    '$route': function (to, from){
      if (to.path === "/dashboard" && (from.name === 'declarationForm' || from.name === 'updateDeclarationForm')) {
        this.isPageLoading = true;
        this.getAnsweredQuestionnaires();
        this.verifyAuth();
      }
    }
  },
  async created () {
    let parsed_user = {};
    let bytes = "";
    let decryptedData = "";

    let cipherData = VueCookies.get('misc');

    if (cipherData === null  || cipherData === undefined || cipherData === '') {
      window.location.href = "/login";
    } else {
      bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) {
        let decode = JSON.parse(decryptedData);
        let user = decode.user;
 
        //Check if user exists
        if (user) {
          this.currentUser = user;
          this.name = `${user.first_name} ${user.last_name}`;
          this.picture = user.picture;
          this.manageUserNotifID();
          this.getAnsweredQuestionnaires();

          if (user.employee_no === null || user.employee_no === "" || user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            this.$router.push('/dashboard')
          }
        } else {
          //Run verify auth if data does not exist
          await store.dispatch('login/verifyAuth').then(() => {
            parsed_user = JSON.parse(store.getters['login/currUser']);
            this.currentUser = parsed_user;
            this.name = `${parsed_user.first_name} ${parsed_user.last_name}`;
            this.picture = parsed_user.picture;
            this.manageUserNotifID();
            this.getAnsweredQuestionnaires();
            return;
          }).catch(err => {
            if (err.status == 401) {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          });

          if (parsed_user.employee_no === null || parsed_user.employee_no === "" || parsed_user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
        }
      } else {
        localStorage.setItem('is_session_expired', true);
        localStorage.removeItem("is_consent_agreed");
        VueCookies.remove("misc");
        VueCookies.remove("misc-hs");
        window.location.href = '/login';
      }
    }
  },
  methods: {
    async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
          cssClass: 'toast-custom',
					end: true,
				})
			return toast.present();
		},
    async getAnsweredQuestionnaires() {
      let user = this.currentUser;
      this.isPageLoading = true;      

      store.dispatch('questionnaire/getAnsweredQuestionaires', user.id).then((res) => {
        const data = res.data;

        this.answeredForms = data;
        this.isPageLoading = false;
      }).catch(err => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }
      });
    },
    doRefresh(event) {
      this.isPageLoading = true;
      event.target.complete();
      let user = this.currentUser;

      store.dispatch('questionnaire/getAnsweredQuestionaires', user.id).then(res => {
        const data = res.data;

        this.answeredForms = data;
        this.isPageLoading = false;
        this.verifyAuth();   
      }).catch(err => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }
        this.isPageLoading = false;
      });
    },
    manageUserNotifID() {
      let user = this.currentUser;
      let generated_uuid = localStorage.getItem('GeneratedOneSignalPlayerID');
      let current_uuid = localStorage.getItem('OneSignalPlayerID');
      this.isPageLoading = true;

      if (generated_uuid) {

        if (current_uuid === null) {
          store.dispatch('user/getUserNotifID', user.id).then(list => {
            let uuids = list;
            uuids.forEach(res => {
              let payload = {
                id: res.id,
                data: {
                  user_id: user.id,
                  player_id: generated_uuid,
                }
              }

              if (res.player_id === "") {
                store.dispatch('user/updateUserNotifID', payload).then(() => {
                  localStorage.setItem('OneSignalPlayerID', generated_uuid);
                  localStorage.removeItem('GeneratedOneSignalPlayerID');
                }).catch(err => {
                  if (err.status == 401) {
                    localStorage.setItem('is_session_expired', true);
                    localStorage.removeItem("is_consent_agreed");
                    VueCookies.remove("misc");
                    VueCookies.remove("misc-hs");
                    window.location.href = '/login';
                  }
                });
              }
            });
          }).catch(err => {
            if (err.status == 401) {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          })
        }
        
        if (current_uuid !== generated_uuid) {
          let payload = {
            user_id: user.id,
            player_id: generated_uuid,
          }

          store.dispatch('user/addUserNotifID', payload).then(() => {
            localStorage.setItem('OneSignalPlayerID', generated_uuid);
            localStorage.removeItem('GeneratedOneSignalPlayerID');
          }).catch(err => {
            if (err.status == 401) {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          });
        }
      }
    },
    async verifyAuth() {
      let parsed_user = {};

      await store.dispatch('login/verifyAuth').then(() => {
        parsed_user = JSON.parse(store.getters['login/currUser']);
        return;
      }).catch(err => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }
      });

      if (parsed_user.employee_no === null || parsed_user.employee_no === "" || parsed_user.employee_no === undefined) {
        this.$router.push('/onboard')
      } else {
        return;
      }
    }
  }
}
</script>